import React, { useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { motion } from 'framer-motion';
import { Autoplay, EffectCreative } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-creative';

const Slider = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef(null);

  const slides = [
    // { img: '/images/slider/slider1.jpg', title: '25 Yıllık Deneyim Güvencesiyle…', bodyText: 'Güçlü Inşaat Taahhüt Mimarlık Altyapımızla,25 Yılın Üzerindeki Birikimimizi Ve Deneyimimizi Başarıyla Birleştirerek Inşaat Yapı Sektöründe Deneyimli Güven Veren Firmasıyız.' },
    { img: '/images/slider/slider2.jpg', title: '25 Yıllık Deneyim Güvencesiyle…', bodyText: 'Güçlü Inşaat Taahhüt Mimarlık Altyapımızla,25 Yılın Üzerindeki Birikimimizi Ve Deneyimimizi Başarıyla Birleştirerek Inşaat Yapı Sektöründe Deneyimli Güven Veren Firmasıyız.' },
  ];

  const goToSlide = (index) => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index);
    }
  };

  // Animasyon variantları
  const textVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
    exit: { opacity: 0, y: -50, transition: { duration: 0.5 } }
  };

  return (
    <div className='h-[calc(100vh-4rem)] bg-black relative'>
      <div className='w-full absolute z-40 bottom-0 left-0 col-7-container mb-10'>
        <div className='col-7-container-inner'>
          <div className='w-full h-full flex justify-between'>
            <div className='flex space-x-2'>
              {slides.map((_, index) => (
                <div
                  key={index}
                  className={index === activeIndex ? 'h-2 w-2 bg-white' : 'h-2 w-2 border-white border'}
                  onClick={() => goToSlide(index)}
                  style={{ cursor: 'pointer' }}
                ></div>
              ))}
            </div>

            <div className="flex h-[0.5rem] space-x-5">
              <a target='_blank' href='https://www.linkedin.com/company/misura-teknoloji%CC%87/?viewAsMember=true' className='block h-[0.8rem] hover:scale-125 transition-all duration-300'><img className='h-full' src="/images/icons/social/3.svg" alt="social icon" /></a>
              <a target='_blank' href='https://www.instagram.com/misurateknoloji/' className='block h-[0.8rem] hover:scale-125 transition-all duration-300'><img className='h-full' src="/images/icons/social/2.svg" alt="social icon" /></a>
              <a target='_blank' href='https://www.youtube.com/@Misurateknoloji' className='block h-[0.8rem] hover:scale-125 transition-all duration-300'><img className='h-full' src="/images/icons/social/4.svg" alt="social icon" /></a>
              <a target='_blank' href='https://www.facebook.com/profile.php?id=61555120416007&locale=tr_TR' className='block h-[0.8rem] hover:scale-125 transition-all duration-300'><img className='h-full' src="/images/icons/social/1.svg" alt="social icon" /></a>
            </div>
          </div>
        </div>
      </div>

      <Swiper
        ref={swiperRef}
        onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: ['-120%', 0, -500],
          },
          next: {
            shadow: true,
            translate: ['120%', 0, -500],
          },
        }}
        effect={'creative'}
        autoplay={{
          delay: 3000, 
          disableOnInteraction: false, 
          
        }}

        
        modules={[EffectCreative,Autoplay]}
        className='h-full'
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <motion.div className={`h-full w-full bg-black`}>
              <div className='h-[calc(40vh-2rem)] w-full absolute top-0 left-0'>
                <div className='col-7-container h-full'>
                  <div className='col-7-container-inner h-full flex justify-end items-end'>
                    <div className='md:w-[37%]'>
                      <motion.h1
                        className='text-45 font-bold text-misuraBlue mb-3 text-right'
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        variants={textVariants}
                        key={activeIndex}
                      >
                        {slide.title}
                      </motion.h1>
                      <motion.p
                        className='font-light text-16  leading-6 text-white text-right'
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        variants={textVariants}
                        key={`body-${activeIndex}`}
                      >
                        {slide.bodyText}
                      </motion.p>
                    </div>
                  </div>
                </div>
              </div>
              <img src={slide.img} className='h-full w-full object-cover' alt={`slide ${index + 1}`} />
            </motion.div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Slider;

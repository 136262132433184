import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { DATA } from '../data/urunler-data';
import { motion } from 'framer-motion';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

const UrunlerDetay = () => {
  const { id, id2 } = useParams();
  const [open, setOpen] = useState(false); // State to manage lightbox open/close
  const [currentIndex, setCurrentIndex] = useState(0); // State to track the clicked image index
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const category = DATA.find(category => category.link === id);
  const product = category?.products.find(product => product.link === id2);
console.log(product)
  // Prepare the slides for the lightbox
  const slides = product?.imagesData?.images.map((item) => ({
    src: `/images/urunler/${category.link}/${product.link}/${item}.jpg`,
  })) || [];

  // Function to open the lightbox and set the current index
  const handleImageClick = (index) => {
    setCurrentIndex(index);
    setOpen(true);
  };

  return (
    <div className="col-7-container">
      <div className="col-7-container-inner py-24 flex flex-col space-y-1">
        <div className='text-45 text-white font-bold mb-16'>{product.title}</div>

        <div className='w-full  relative overflow-hidden pb-20'>
          <img
            src={`/images/urunler/${category.link}/${product.link}/main.jpg`}
            className='w-full h-full object-cover aspect-video '
            alt={`${product.title}`}
          />
          <div className='absolute h-full w-full z-50 top-0 left-0 flex flex-col '>
            <motion.div
              className='h-1/3 w-full bg-white'
              initial={{ x: 0 }}
              whileInView={{ x: '-100%' }}
              transition={{ duration: 1.5, ease: 'easeInOut' }}
            ></motion.div>
            <motion.div
              className='h-1/3 w-full bg-white'
              initial={{ x: 0 }}
              whileInView={{ x: '100%' }}
              transition={{ duration: 1.5, ease: 'easeInOut' }}
            ></motion.div>
            <motion.div
              className='h-1/3 w-full bg-white'
              initial={{ x: 0 }}
              whileInView={{ x: '-100%' }}
              transition={{ duration: 1.5, ease: 'easeInOut' }}
            ></motion.div>
          </div>
        </div>

        <div className='grid grid-cols-1 md:grid-cols-3 gap-1 w-full'>
          {
            product?.imagesData?.images?.map((item, index) => {
              const randomDelay = Math.random();
              return(
                <div                 onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)} className={`${hoveredIndex !== null && hoveredIndex !== index ? 'opacity-40' : 'opacity-100'} transition-opacity duration-300`}>
              <motion.div
              initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  exit={{ opacity: 0 }}

                  transition={{
                    duration: 0.5,
                    delay: randomDelay * 1.2
                  }}
              
              key={index} className='h-80'>
                <img
                  src={`/images/urunler/${category.link}/${product.link}/${item}.jpg`}
                  className='h-full w-full object-cover aspect-video cursor-pointer'
                  alt={`${item}`}
                  onClick={() => handleImageClick(index)} // Open lightbox on click
                />
              </motion.div>
              </div>
              )
            })
          }
        </div>

        {/* Lightbox component */}
        {open && (
          <Lightbox
            open={open}
            close={() => setOpen(false)}
            slides={slides}
            index={currentIndex} // Start with the clicked image
          />
        )}




{product.catalog && product.catalog.length > 0 && (
  <div className='w-full flex flex-col text-white pt-20 pb-10'>
    <h6 className='text-white text-2xl'>KATALOGLAR</h6>
    {product.catalog.map((item, index) => {
      if (!item.link) return null; 

      return (
        <a 
          key={index} 
          className='underline text-white hover:text-gray-400 transition-colors' 
          target='_blank' 
          rel='noopener noreferrer' 
          href={`/images/urunler/${category.link}/${product.link}/${item.link}`}
        >
          {item.title}
        </a>
      );
    })}
  </div>
)}




        {product.videos && product.videos.length > 0 && (
  <div className='grid grid-cols-1 gap-1'>
    <h6 className='text-white text-2xl my-10'>VİDEOLAR</h6>
    {product.videos.map((item, index) => {
      if (!item.link) return null; // 
      
      return (
        <a key={index} href={`https://www.youtube.com/embed/${item.link}`}  target='_blank' rel="noopener noreferrer" className='aspect-video grid relative'>
          <div className='absolute top-0 left-0 bg-slate-500 z-50 h-full w-full opacity-0'></div>
          <div className='h-full w-full'>
            <iframe 
              width='100%' 
              height='100%' 
              src={`https://www.youtube.com/embed/${item.link}`} 
              title={`Video ${index + 1}`} 
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        </a>
      );
    })}
  </div>
)}




      </div>
    </div>
  );
};

export default UrunlerDetay;

export const DATA = [
    {
        title: "CNC İŞLEME MERKEZİ",
        subtitle: "Detaylara Azami Özen Gösterilerek Üretilen Her Makinemizin Tek Amacı Size Benzersiz Bir Hizmet Kalitesi Ve Uzmanlık Sunmaktır.",
        image: "/images/anasayfa/category/cnc-isleme-merkezi.svg",
        link: "cnc-isleme-merkezi",
        products: [
            {
                title: "POTENZA M4",
                image: "main.jpg",
                link: "potenza-m4",

                imagesData: {
                    images: ["1", "2"],
                },
                catalog: [
              

                ],
                videos:[
            
                ]
                
            },





            {
                title: "DINAMİCA M4",
                image: "/images/urunler/1.jpg",
                link: "dinamica-m4",
                imagesData: {
                    images: ["1", "2","3","4"],
                },
                catalog: [


                ],
                videos:[
             
                ]
            },



            {
                title: "FORZA M4",
                image: "/images/urunler/1.jpg",
                link: "forza-m4",
                imagesData: {
                    images: ["1", "2","3","4"],
                },
                catalog: [


                ],
                videos:[
             
                ]
            },

        ]
    },

    
    {
        title: "KESİM MAKİNELERİ",
        subtitle: "Detaylara Azami Özen Gösterilerek Üretilen Her Makinemizin Tek Amacı Size Benzersiz Bir Hizmet Kalitesi Ve Uzmanlık Sunmaktır.",
        image: "/images/anasayfa/category/kesim-makineleri.svg",
        link: "kesim-makineleri",
        products: [
            // {
            //     title: "GRANDE R 500",
            //     image: "/images/urunler/1.jpg",
            //     link: "1"
            // },
            // {
            //     title: "AX-550-PLUS",
            //     image: "/images/urunler/1.jpg",
            //     link: "ax-550-plus",
            //     imagesData: {
            //         images: ["1", "2","3","4","5","6","7","8","9"],
            //     },
            //     catalog: [
        
                    

            //     ],
            //     videos:[
            //         {
            //             title:'',
            //             link:'6Ik_x5mrrOQ'
            //         },
            //         {
            //             title:'',
            //             link:'9zDfay0Xd0M'
            //         },
      
                    
            //     ]
            // },
            {
                title: "MONZA R 475",
                image: "/images/urunler/1.jpg",
                link: "monza-r-475",
                imagesData: {
                    images: ["1", "2","3","4"],
                },
                // catalog: [
                //     {
                //         title:'ATLANTIC AK305 WT TR',
                //         link:'ATLANTIC-AK305-WT-TR.docx'
                //     },

                //     {
                //         title:'ATLANTIC AK305 WT ENG',
                //         link:'ATLANTIC-AK305-WT-TR.docx'
                //     },

                // ],
                videos:[
             
                ]
            },






            {
                title: "TİPO-300",
                image: "/images/urunler/1.jpg",
                link: "tipo-300",
                imagesData: {
                    images: ["1", "2","3","4"],
                },
                catalog: [


                ],
                videos:[
             
                ]
            },




            


            {
                title: "GRANDE R 500",
                image: "/images/urunler/1.jpg",
                link: "grande-r-500",
                imagesData: {
                    images: ["1", "2","3","4"],
                },
                catalog: [


                ],
                videos:[
             
                ]
            },



            {
                title: "FUTURA R 500",
                image: "/images/urunler/1.jpg",
                link: "futura-r-500",
                imagesData: {
                    images: ["1", "2","3","4"],
                },
                catalog: [


                ],
                videos:[
             
                ]
            },













            // {
            //     title: "PREMİO R 600 PLUS",
            //     image: "/images/urunler/1.jpg",
            //     link: "1"
            // },
            // {
            //     title: "RİTMO R 500",
            //     image: "/images/urunler/1.jpg",
            //     link: "1"
            // },
            // {
            //     title: "SPRİNT 90",
            //     image: "/images/urunler/1.jpg",
            //     link: "1"
            // },
            // {
            //     title: "TİPO 300",
            //     image: "/images/urunler/1.jpg",
            //     link: "1"
            // },
            
        ]
    },


    {
        title: "KÖŞE PRESİ MAKİNELERİ",
        subtitle: "Detaylara Azami Özen Gösterilerek Üretilen Her Makinemizin Tek Amacı Size Benzersiz Bir Hizmet Kalitesi Ve Uzmanlık Sunmaktır.",
        image: "/images/anasayfa/category/kose-presi-makineleri.svg",
        link: "kose-presi-makineleri",
        products: [
            {
                title: "AUTO CRAB",
                image: "/images/urunler/1.jpg",
                link: "auto-crab",

                imagesData: {
                    mainImage: "",
                    images: ["1", "2", "3", "4","5","6","7","8"],
                },
                catalog: [
                    {
                        title:'AUTO CRAB AUTOMATIC CORNER CRIMPING MACHINE EN',
                        link:'auto-crab-automatic-corner-crimping-machine-en.pdf'
                    },
                    {
                        title:'AUTO CRAB OTOMATİK PRES TR',
                        link:'auto-crab-otomatik-pres-tr.pdf'
                    },

                ],
                videos:[
                    {
                        title:'',
                        link:'L6z9tG4MdQg'
                    },
                ]
            },


        ]
    },


    
    {
        title: "PROFİL KESİM VE İŞLEME MERKEZİ",
        subtitle: "Detaylara Azami Özen Gösterilerek Üretilen Her Makinemizin Tek Amacı Size Benzersiz Bir Hizmet Kalitesi Ve Uzmanlık Sunmaktır.",
        image: "/images/anasayfa/category/bukum-makineleri.svg",
        link: "profil-kesim-ve-isleme-merkezi",
        products: [
            // {
            //     title: "1",
            //     image: "/images/urunler/1.jpg",
            //     link: "1",

            //     imagesData: {
            //         mainImage: "",
            //         images: ["", "", "", ""],
            //     },
            //     catalog: "",
            // },

            {
                title: "CRONOS 100",
                image: "/images/urunler/1.jpg",
                link: "cronos-100",
                imagesData: {
                    images: ["1", "2","3","4"],
                },
                catalog: [


                ],
                videos:[
             
                ]
            },
            
            {
                title: "CRONOS 300",
                image: "/images/urunler/1.jpg",
                link: "cronos-300",
                imagesData: {
                    images: ["1", "2","3","4"],
                },
                catalog: [


                ],
                videos:[
             
                ]
            },



            
            {
                title: "CRONOS 600",
                image: "/images/urunler/1.jpg",
                link: "cronos-300",
                imagesData: {
                    images: ["1", "2","3","4"],
                },
                catalog: [


                ],
                videos:[
             
                ]
            },


        ]
    },

    {
        title: "KOPYA FREZE MAKİNELERİ",
        subtitle: "Detaylara Azami Özen Gösterilerek Üretilen Her Makinemizin Tek Amacı Size Benzersiz Bir Hizmet Kalitesi Ve Uzmanlık Sunmaktır.",
        image: "/images/anasayfa/category/kopya-freze-makineleri.svg",
        link: "kopya-freze-makineleri",
        products: [
            // {
            //     title: "MATERİA",
            //     image: "/images/urunler/1.jpg",
            //     link: "1"
            // },


        ],

    },

    
    {
        title: "KERTME MAKİNALARI",
        subtitle: "Detaylara Azami Özen Gösterilerek Üretilen Her Makinemizin Tek Amacı Size Benzersiz Bir Hizmet Kalitesi Ve Uzmanlık Sunmaktır.",
        image: "/images/anasayfa/category/kertme-makinalari.svg",
        link: "kertme-makineleri",
        products: [
            // {
            //     title: "CENTO",
            //     image: "/images/urunler/1.jpg",
            //     link: "1"
            // },



                        
            {
                title: "VIRTUS",
                image: "/images/urunler/1.jpg",
                link: "virtus",
                imagesData: {
                    images: ["1", "2","3","4"],
                },
                catalog: [


                ],
                videos:[
             
                ]
            },
        ]
    },



















    






    {
        title: "ATÖLYE EKİPMANLARI | LOJİSTİK SİSTEMLER",
        subtitle: "Detaylara Azami Özen Gösterilerek Üretilen Her Makinemizin Tek Amacı Size Benzersiz Bir Hizmet Kalitesi Ve Uzmanlık Sunmaktır.",
        image: "/images/anasayfa/category/atolye-ekipmanlari.svg",
        link: "atolye-ekipmanlari-lojistik-sistemler",
        products: [
            {
                title: "CARR 03",
                image: "/images/urunler/1.jpg",
                link: "carr-03",
                catalog: [
                    {
                        title:'FOM INDUSTRIE EN-CARR 03.DOC',
                        link:'fom-industrie-en-carr-03-doc'
                    },

                ],
                videos:[
       
                ]
            },
            {
                title: "CARR 100",
                image: "/images/urunler/1.jpg",
                link: "carr-100",
                catalog: [
                    {
                        title:'FOM INDUSTRIE EN-CARR 100 Q.DOC.pdf',
                        link:'fom-industrie-en-carr-100-q-doc.pdf'
                    }

                ],
                videos:[
           
                ]
            },
            {
                title: "CARR 200",
                image: "/images/urunler/1.jpg",
                link: "carr-200",
                catalog: [
                    {
                        title:'FOM INDUSTRIE EN-CARR 200 Q.DOC.pdf',
                        link:'fom-industrie-en-carr-200-q-doc.pdf'
                    },

                ],
                videos:[
          
                ]
            },
            
            {
                title: "CARR 230",
                image: "/images/urunler/1.jpg",
                link: "carr-230",
                catalog: [
                    {
                        title:'FOM INDUSTRIE EN-CARR 230 Q.DOC.pdf',
                        link:'fom-industrie-en-carr-230-q-doc.pdf'
                    },

                ],
                videos:[
        
                ]
            },
            {
                title: "CARR 250",
                image: "/images/urunler/1.jpg",
                link: "carr-250",
                catalog: [
                    {
                        title:'FOM INDUSTRIE EN-CARR 250 Q.DOC.pdf',
                        link:'fom-industrie-en-carr-250-q-doc.pdf'
                    },

                ],
                videos:[
      
                ]
            },
            // {
            //     title: "CARR 300",
            //     image: "/images/urunler/1.jpg",
            //     link: "carr-300"
            // },
            // {
            //     title: "CARR 400",
            //     image: "/images/urunler/1.jpg",
            //     link: "carr-400"
            // },
            // {
            //     title: "CARR 500",
            //     image: "/images/urunler/1.jpg",
            //     link: "carr-500"
            // },
            // {
            //     title: "CARR 600",
            //     image: "/images/urunler/1.jpg",
            //     link: "carr-600"
            // },
            // {
            //     title: "CARR 900",
            //     image: "/images/urunler/1.jpg",
            //     link: "carr-900"
            // },
            // {
            //     title: "CARR CB",
            //     image: "/images/urunler/1.jpg",
            //     link: "carr-cb"
            // },
            // {
            //     title: "CARR FE",
            //     image: "/images/urunler/1.jpg",
            //     link: "carr-fe"
            // },
            // {
            //     title: "CARR PA",
            //     image: "/images/urunler/1.jpg",
            //     link: "carr-pa"
            // },
            // {
            //     title: "CARR PF",
            //     image: "/images/urunler/1.jpg",
            //     link: "carr-pf"
            // },
            // {
            //     title: "CARR PR",
            //     image: "/images/urunler/1.jpg",
            //     link: "carr-pr"
            // },
            // {
            //     title: "HANDLING BENCH WITH OPERATOR TRANSIT",
            //     image: "/images/urunler/1.jpg",
            //     link: "handling-bench-with-operator-transit"
            // },
            // {
            //     title: "JOB 10 -360",
            //     image: "/images/urunler/1.jpg",
            //     link: "job-10-360"
            // },
            // {
            //     title: "JOB 15",
            //     image: "/images/urunler/1.jpg",
            //     link: "job-15"
            // },
            // {
            //     title: "JOB 20",
            //     image: "/images/urunler/1.jpg",
            //     link: "job-20"
            // },
            // {
            //     title: "JOINT",
            //     image: "/images/urunler/1.jpg",
            //     link: "joint"
            // },
            // {
            //     title: "JOINT_XL",
            //     image: "/images/urunler/1.jpg",
            //     link: "joint-xl"
            // },
            // {
            //     title: "RULLIERA_250",
            //     image: "/images/urunler/1.jpg",
            //     link: "rulliera-250"
            // },
            // {
            //     title: "RULLIERA_400",
            //     image: "/images/urunler/1.jpg",
            //     link: "rulliera-400"
            // },
            // {
            //     title: "TRASLO_1",
            //     image: "/images/urunler/1.jpg",
            //     link: "traslo-1"
            // },
        ]
    },





    {
        title: "BÜKÜM MAKİNELERİ",
        subtitle: "Detaylara Azami Özen Gösterilerek Üretilen Her Makinemizin Tek Amacı Size Benzersiz Bir Hizmet Kalitesi Ve Uzmanlık Sunmaktır.",
        image: "/images/anasayfa/category/bukum-makineleri.svg",
        link: "bukum-makineleri",
        products: [
            // {
            //     title: "1",
            //     image: "/images/urunler/1.jpg",
            //     link: "1",

            //     imagesData: {
            //         mainImage: "",
            //         images: ["", "", "", ""],
            //     },
            //     catalog: "",
            // },


        ]
    },


    





































    



    {
        title: "DİĞER",
        subtitle: "Detaylara Azami Özen Gösterilerek Üretilen Her Makinemizin Tek Amacı Size Benzersiz Bir Hizmet Kalitesi Ve Uzmanlık Sunmaktır.",
        image: "/images/anasayfa/category/bukum-makineleri.svg",
        link: "diger",
        products: [



            
            {
                title: "ATLANTİC AK 305 WT",
                image: "/images/urunler/1.jpg",
                link: "atlantic-ak-305-wt",
                imagesData: {
                    images: ["1", "2","3"],
                },
                catalog: [
                    {
                        title:'ATLANTIC-AK305-WT-TR.docx',
                        link:'ATLANTIC-AK305-WT-TR.docx'
                    },
                    {
                        title:'ATLANTIC-AK305-WT-ENG.docx',
                        link:'ATLANTIC-AK305-WT-ENG.docx'
                    },

                ],
                videos:[
             
                ]
            },




            {
                title: "ATLANTIC AK 305 T",
                image: "/images/urunler/1.jpg",
                link: "atlantic-ak-305-t",
                imagesData: {
                    images: ["1", "2","3"],
                },
                catalog: [
                    {
                        title:'ATLANTIC - AK305 T TR.docx',
                        link:'ATLANTIC - AK305 T TR.docx'
                    },
                    {
                        title:'ATLANTIC - AK305 T ENG.docx',
                        link:'ATLANTIC - AK305 T ENG.docx'
                    },

                ],
                videos:[
             
                ]
            },



            {
                title: "ATLANTIC 305",
                image: "/images/urunler/1.jpg",
                link: "atlantic-305",
                imagesData: {
                    images: ["1", "2"],
                },
                catalog: [
                    {
                        title:'ATLANTIC AK 305 TR.docx',
                        link:'ATLANTIC AK 305 TR.docx'
                    },
                    {
                        title:'ATLANTIC AK 305 ENG.docx',
                        link:'ATLANTIC AK 305 ENG.docx'
                    },

                ],
                videos:[
             
                ]
            },





            {
                title: "ATLANTIC 400",
                image: "/images/urunler/1.jpg",
                link: "atlantic-400",
                imagesData: {
                    images: ["1", "2","3"],
                },
                catalog: [
                    {
                        title:'ATLANTIC - 400 TR.docx',
                        link:'ATLANTIC - 400 TR.docx'
                    },
                    {
                        title:'ATLANTIC - 400 ENG.docx',
                        link:'ATLANTIC - 400 ENG.docx'
                    },

                ],
                videos:[
             
                ]
            },




            {
                title: "ATLANTIC 400 ULTRA",
                image: "/images/urunler/1.jpg",
                link: "atlantic-400-ultra",
                imagesData: {
                    images: ["1", "2","3"],
                },
                catalog: [
                    {
                        title:'ATLANTIC - 400 ULTRA TR.docx',
                        link:'ATLANTIC - 400 ULTRA TR.docx'
                    },
                    {
                        title:'ATLANTIC - 400 ULTRA ENG.docx',
                        link:'ATLANTIC - 400 ULTRA ENG.docx'
                    },

                ],
                videos:[
             
                ]
            },



            {
                title: "ATLANTIC 500",
                image: "/images/urunler/1.jpg",
                link: "atlantic-500",
                imagesData: {
                    images: ["1", "2","3"],
                },
                catalog: [
                    {
                        title:'ATLANTIC - 500  TR.docx',
                        link:'ATLANTIC - 500  TR.docx'
                    },
                    {
                        title:'ATLANTIC - 500  ENG .docx',
                        link:'ATLANTIC - 500  ENG .docx'
                    },

                ],
                videos:[
             
                ]
            },




            {
                title: "BERING",
                image: "/images/urunler/1.jpg",
                link: "bering",
                imagesData: {
                    images: ["1", "2","3"],
                },
                catalog: [
                    {
                        title:'BERING TR.docx',
                        link:'BERING TR.docx'
                    },
                    {
                        title:'BERING  ENG.docx',
                        link:'BERING  ENG.docx'
                    },

                ],
                videos:[
             
                ]
            },



            {
                title: "KAI",
                image: "/images/urunler/1.jpg",
                link: "kai",
                imagesData: {
                    images: ["1", "2","3"],
                },
                catalog: [
                    {
                        title:'KAI 150  TR.docx',
                        link:'KAI 150  TR.docx'
                    },
                    {
                        title:'KAI 150  ENG.docx',
                        link:'KAI 150  ENG.docx'
                    },

                ],
                videos:[
             
                ]
            },



            {
                title: "KOPYA FREZE",
                image: "/images/urunler/1.jpg",
                link: "kopya-freze",
                imagesData: {
                    images: ["1", "2","3"],
                },
                catalog: [
       
                    {
                        title:'ADRIATIC - IP  TR.docx',
                        link:'ADRIATIC - IP  TR.docx'
                    },
                    {
                        title:'ADRIATIC - IP  eng .docx',
                        link:'ADRIATIC - IP  eng .docx'
                    },
                ],
                videos:[
             
                ]
            },


            {
                title: "PASIFIC",
                image: "/images/urunler/1.jpg",
                link: "pasific",
                imagesData: {
                    images: ["1", "2","3"],
                },
                catalog: [
                    {
                        title:'PACIFIC - IP  TR.docx',
                        link:'PACIFIC - IP  TR.docx'
                    },
                    {
                        title:'PACIFIC - IP  eng.docx',
                        link:'PACIFIC - IP  eng.docx'
                    },

                ],
                videos:[
             
                ]
            },


        ]
    },


]